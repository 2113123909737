import React, { useEffect } from "react";

export default function VersionPage() {
  useEffect(() => {
    if (typeof window !== "undefined") {
      const data = {
        commitHash: process.env.GIT_HASH,
        timestamp: new Date().toISOString(),
      };
      document.querySelector("pre").textContent = JSON.stringify(data, null, 2);
    }
  }, []);

  return typeof window === "undefined" ? null : <pre />;
}

// This tells Gatsby to skip creating a React component wrapper
export const Head = () => {
  return [
    <title key="title">Version API</title>,
    <meta
      key="content-type"
      httpEquiv="Content-Type"
      content="application/json"
    />,
  ];
};
